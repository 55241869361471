





















































































































































































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'

import circle from '@turf/circle'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import omnivore from 'leaflet-omnivore'
import utils from '@/utils/utils'
import { IZone, IZoneType } from '../Map/types'

const props = {
  center: Array,
  activeSiteId: undefined,
  shape: undefined
}

interface IKeysCount {
  Polygon?: number
}

const toTitleCase = str =>
  str?.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

const NEW_ZONE = {
  id: 'new',
  name: 'New Zone',
  zone_type: 'alert',
  coordinate_list: [],
  note: ''
}

export default Vue.extend({
  name: 'ZonesWidget',
  props,
  components: {},
  data: () => ({
    selectZoneItems: [],
    zoneEditMode: false,
    dialog: false,
    importMessage: null,
    status: null,
    initialName: ''
  }),
  async mounted(): Promise<void> {
    // clear selected zone
    this.selectedZoneId = null
    this.zoneEditMode = false
    this.activeZoneModel = null
    if (this.activePlan) {
      this.updatePlanZones()
      this.$bus.$on('activateZone', id => (this.selectedZoneId = id))
    } else {
      await this.refreshAllZones(this.activeSiteId)
    }
  },
  beforeDestroy(): void {
    this.$bus.$off('activateZone')
  },
  computed: {
    ...mapState('zones', ['zoneTypes', 'activeZoneId', 'activeZone']),
    ...mapGetters('zones', ['zonesList']),
    ...mapState('plans', ['activePlan']),
    activeZoneModel: {
      get(): IZone {
        return this.activeZone
      },
      set(val: IZone): void {
        this.setActiveZone(val)
      }
    },
    selectedZoneId: {
      get(): number {
        return this.activeZoneId
      },
      set(val: number): void {
        this.selectZone(val)
      }
    },
    zoneType(): IZoneType[] {
      return this.zoneTypes.map(z => ({
        value: z,
        text: toTitleCase(z)
      }))
    }
  },
  methods: {
    ...mapActions('zones', {
      refreshAllZones: 'FETCH_ZONES',
      refreshZone: 'FETCH_ZONE',
      createZone: 'CREATE_ZONE',
      updateZone: 'UPDATE_ZONE',
      deleteZone: 'DELETE_ZONE',
      selectZone: 'SELECT_ZONE',
      setActiveZone: 'setActiveZone'
    }),
    ...mapActions('plans', {
      createZonePlan: 'CREATE_ZONE',
      updateZonePlan: 'UPDATE_ZONE',
      deleteZonePlan: 'DELETE_ZONE'
    }),
    genNewZone(): void {
      const [lat, lng] = this.center
      const shape = bboxPolygon(
        bbox(circle([lng, lat], 0.2, { units: 'kilometers' }))
      )

      const {
        geometry: { coordinates = [] }
      } = shape
      let [coordinate_list = []] = coordinates
      coordinate_list = coordinate_list.map(([lng, lat]) => [lat, lng])

      const newZone = { ...NEW_ZONE, coordinate_list }

      this.selectZoneItems.push(newZone)
      this.activeZoneModel = newZone
      this.selectedZoneId = 'new'
      this.$nextTick(() => {
        this.zoneEditMode = true
      })
    },
    cancelZoneCreation(): void {
      if (this.selectedZoneId === 'new') {
        this.selectZoneItems.pop()
        this.selectedZoneId = null
      }
    },
    onCancelZoneChange(): void {
      this.zoneEditMode = false
      this.activePlan || this.selectedZoneId === 'new'
        ? this.cancelZoneCreation()
        : this.refreshZone({
          siteID: this.activeSiteId,
          id: this.selectedZoneId,
        })
    },
    async onUpdateZone(): Promise<void> {
      const zone = Object.assign(this.activeZoneModel, {
        coordinate_list: this.shape,
        coordinates: JSON.stringify(this.shape),
        note: this.activeZoneModel.note,
      })
      if (zone.id === 'new') {
        if (this.activePlan) {
          let { id } = await this.createZonePlan({
            ...zone
          })
          this.updatePlanZones()
          this.selectedZoneId = id
        } else {
          const newZone = {
            ...zone,
            site_id: this.activeSiteId,
          }
          this.selectedZoneId = await this.createZone(newZone)

          // Set the new active zone, as opposed to keeping the
          // values as the NEW_ZONE object.
          if (this.selectedZoneId) {
            this.setActiveZone({
              ...newZone,
              site_id: Number(this.activeSiteId),
              id: this.selectedZoneId
            })
          }
        }
      } else {
        this.activePlan
          ? this.updateZonePlan(zone)
          : await this.updateZone(zone)

        this.refreshAllZones(this.activeSiteId)
      }
      if (this.activePlan) this.initialName = zone.name
      this.zoneEditMode = false
    },
    async onDeleteZone(): Promise<void> {
      if (this.activePlan) {
        this.deleteZonePlan(this.activeZoneModel)
      } else {
        await this.deleteZone(this.activeZoneModel)
      }
      this.selectedZoneId = null
      if (!this.activePlan) await this.refreshAllZones(this.activeSiteId)
      else this.updatePlanZones()
      this.dialog = false
    },
    updatePlanZones(): void {
      this.selectZoneItems = [{ name: 'None', id: null }].concat(
        this.activePlan.site_plan.zones || []
      )
    },
    async importKMLZones(e): Promise<void> {
      const keysCount: IKeysCount = {}
      let file = e.target.files[0]
      this.importedPlan = await utils.readTextFile(file)
      var shape = omnivore.kml.parse(this.importedPlan)
      let keys = Object.keys(shape._layers)
      keys.forEach(key => {
        const geometry = shape._layers[key].feature.geometry.type
        keysCount[geometry] = keysCount[geometry] + 1 || 1
        if (geometry === 'Polygon') {
          let coordinate_list = shape._layers[
            key
          ].feature.geometry.coordinates[0].map(v => [v[1], v[0]])
          this.createZonePlan({
            ...shape._layers[key].feature.geometry,
            id: 'new',
            name:
              (shape._layers[key].feature.properties &&
                shape._layers[key].feature.properties.name) ||
              'New Zone',
            zone_type: 'alert',
            note: '',
            coordinate_list
          })
          this.$bus.$emit('mapCenter', 'planMap', coordinate_list[0], 14)
        }
      })
      if ('Polygon' in keysCount) {
        this.status = 'success'
        const polygonCount = keysCount.Polygon
        this.importMessage = `Success: ${polygonCount} zone${
          polygonCount != 1 ? 's' : ''
        } imported`
      } else {
        if (Object.keys(keysCount).length === 0) {
          this.status = 'error'
          this.importMessage = 'Import failed: Invalid or empty KML file'
        } else {
          this.status = 'warning'
          this.importMessage = 'No polygon to import: file only contains '
          Object.keys(keysCount).forEach((key, i, arr) => {
            this.importMessage += `${
              i != 0 && i != arr.length - 1
                ? ', '
                : arr.length > 1 && i == arr.length - 1
                ? ' and '
                : ''
            }${keysCount[key]} ${key}`
          })
        }
      }
      this.updatePlanZones()
    }
  },
  watch: {
    selectedZoneId(value): void {
      this.importMessage = null
      this.selectZone(value)
      this.$emit(
        'activateZone',
        this.selectZoneItems.find(zone => zone.id === value) || {}
      )
    },
    zonesList(): void {
      this.selectZoneItems = [{ name: 'None', id: null }].concat(this.zonesList)
    },
    zoneEditMode(v): void {
      this.$emit('zoneEditModeRefresh', v)
      this.initialName = this.activeZoneModel.name
    },
    activePlan(v): void {
      if (v) {
        this.selectedZoneId = null
        this.zoneEditMode = false
        this.updatePlanZones()
      }
    }
  }
})
